import {default as ReactSelect} from "react-select";
import {default as ReactCreateSelect} from "react-select/creatable";
import { Container, Label } from "./Select.styles";

export const Select = ({...props}) => {
  const label = props.placeholder.replace(/[.]|Selecione|o |a /g, '')
  return (
    props.onCreateOption === undefined ? (
      <Container>
      <Label>{label}</Label>
      <ReactSelect {...props} />
    </Container>
    ) : (
      <Container>
      <Label>{label}</Label>
      <ReactCreateSelect {...props} />
    </Container>
    )

  );
};
