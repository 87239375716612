import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "config/authConfig";
import { useCallback } from "react";
import { MainRouter } from "routes/MainRouter";

function App() {
  const { instance, accounts } = useMsal();

  // Função para requisição do accessToken, utilizado para autenticação no backend
  const RequestAccessToken = useCallback(async (setState: React.Dispatch<React.SetStateAction<string | null>>) => {
    const request = {
      account: accounts[0],
      ...loginRequest,
    };

    if(accounts.length === 0) return null;

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    await instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        setState(response.accessToken);
      })
      .catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await instance
            .acquireTokenRedirect(request)
            .then((response: any) => {
              setState(response.accessToken);
            })
            .catch((e) => console.error(e));
        }
        console.error(e);
      });
  }, [instance, accounts]);

  return (
      <MainRouter RequestAccessToken={RequestAccessToken} />
  );
}

export default App;
