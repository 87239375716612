import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  border: 1px solid rgba(204, 204, 204, .5);
  padding: 5px 10px;
  margin: 0;
  display: grid;
  grid-template-areas: "logo nav";
  @media (max-width: 700px) {
    grid-template-areas: "logo test" "nav nav";
  }
`

export const Image = styled.img`
  width: 100px;
`
