import { useMsal } from "@azure/msal-react";
import { Button } from "components/Button";
import { ErrorMessage } from "components/ErrorMessage";
import { InputBox } from "components/InputBox";
import { Select } from "components/Select";
import { SuccessMessage } from "components/SuccessMessage";
import { useState, useEffect } from "react";
import { clientList, getNetworkTrackingMethod, getPolicy, networkList, organizationDetails, saveSchedule } from "services/backend";
import { IPolicyRequest } from "types/policyRequest";
import { ISelectOption } from "types/selectOption";

export const PolicyForm = ({ accessToken }: any) => {
  const { accounts } = useMsal();

  const [cache, setCache] = useState<ISelectOption[] | null>(null);

  const [organizationIndex, setOrganizationIndex] = useState<number>(-1);

  const [organizationArr, setOrganizationArr] = useState<ISelectOption[] | null>(null);
  const [networkArr, setNetworkArr] = useState<ISelectOption[] | null>(null);
  const [clientArr, setClientArr] = useState<ISelectOption[] | null>(null);

  const [organization, setOrganization] = useState<ISelectOption | null>(null);
  const [network, setNetwork] = useState<ISelectOption | null>(null);
  const [client, setClient] = useState<ISelectOption | null>(null);

  const [dueDate, setDueDate] = useState<string>("");

  const [loadingOrg, setLoadingOrg] = useState<boolean>(false);
  const [loadingNet, setLoadingNet] = useState<boolean>(false);
  const [loadingClient, setLoadingClient] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const [requestError, setRequestError] = useState<JSX.Element>(<></>)
  const [requestSuccess, setRequestSuccess] = useState<JSX.Element>(<></>)

  const addUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingRequest(true);
    const networkTrackingMethod = await getNetworkTrackingMethod(network?.value.id, accessToken);
    const deviceInitialPolicy = await getPolicy({ networkId: network?.value.id, macAddress: networkTrackingMethod?.data === 'mac' ? client?.value.mac : client?.value.id }, accessToken);
    const request: IPolicyRequest = {
      adminName: accounts[0]?.name?.replace('(Teltec Solutions)', "").trim() || "",
      adminMail: accounts[0]?.username,
      organizationName: organization?.value.name,
      organizationId: organization?.value.id,
      networkName: network?.value.name,
      networkId: network?.value.id,
      timeZone: network?.value.timeZone,
      macAddress: client?.value.mac,
      deviceAddress: client?.value.id,
      trackingMethod: networkTrackingMethod?.data,
      dueDate: new Date(dueDate).toISOString(),
      devicePolicy: deviceInitialPolicy.devicePolicy !== undefined ? deviceInitialPolicy.devicePolicy : 'Normal',
      groupPolicyId: !deviceInitialPolicy.groupPolicyId ? "null" : deviceInitialPolicy.groupPolicyId,
    }

    // console.log('request', request);
    await saveSchedule(request, accessToken)
      .then((res) => {
        if (res.status !== 201) {
          setRequestError(<><strong>Erro:</strong> {res.data.message}</>)
          setRequestSuccess(<></>)
        } else {
          setRequestError(<></>)
          setRequestSuccess(
            <>
              <p>Dispositivo  liberado com sucesso!</p>
              <p>Endereço IP: {client?.value.ip}</p>
              <p>Endereço MAC: {client?.value.mac}</p>
              <p>Rede: {network?.value.name}</p>
              <p>Liberado até: {new Date(dueDate).toLocaleString('pt-br')}</p>
            </>)
        }
        setLoadingRequest(false);
      })
  };

  // Busca Lista de organizações (cache/request)
  useEffect(() => {
    if (!cache && accessToken) {
      setLoadingOrg(true);
      organizationDetails(accessToken).then((res) => {
        const getOrg = res.data.filter((item: any) => item.label === "Cresol Confederação");
        setCache(getOrg);
        setOrganizationArr(getOrg);
        setLoadingOrg(false);
      });
    } else {
      setOrganizationArr(cache);
    }
  }, [cache, accessToken]);

  // Busca Lista de networks em uma organização (cache/request)
  useEffect(() => {
    if (cache && organization && accessToken) {
      const orgIndex = cache.findIndex((obj: ISelectOption) => obj.value.name === organization.label);
      setOrganizationIndex(orgIndex);
      if (orgIndex !== -1 && !cache[orgIndex].value.networks) {
        setLoadingNet(true);
        networkList(cache[orgIndex].value.id, accessToken).then((res) => {
          const updateCache = cache;
          updateCache[orgIndex].value.networks = res.data;
          setCache(updateCache);
          setNetworkArr(cache[orgIndex].value.networks);
          setLoadingNet(false);
        });
      } else if (orgIndex !== -1) {
        setNetworkArr(cache[orgIndex].value.networks);
      }
    }
  }, [cache, organization, accessToken]);

  // Busca Lista de clients em uma network (cache/request)
  useEffect(() => {
    if (cache && organization && network && organizationIndex !== -1 && accessToken) {
      const networkIndex = cache[organizationIndex].value.networks.findIndex((obj: ISelectOption) => obj.value.name === network.label);
      if (networkIndex !== -1 && !cache[organizationIndex].value.networks[networkIndex].value.clients) {
        setLoadingClient(true);
        clientList(cache[organizationIndex].value.networks[networkIndex].value.id, accessToken)
          .then((res) => {
            const updateCache = cache;
            updateCache[organizationIndex].value.networks[networkIndex].value.clients = res.data;
            setCache(updateCache);
            setClientArr(cache[organizationIndex].value.networks[networkIndex].value.clients);
            setLoadingClient(false);
          });
      } else if (networkIndex !== -1) {
        setClientArr(cache[organizationIndex].value.networks[networkIndex].value.clients);
      }
    }
  }, [cache, organization, network, organizationIndex, accessToken]);

  const selectOrganization = (option: ISelectOption | null) => {
    setOrganization(option);
    selectNetwork(null);
    setNetworkArr(null);
  };

  const selectNetwork = (option: ISelectOption | null) => {
    setNetwork(option);
    selectClient(null);
    setClientArr(null);
  };

  const selectClient = (option: ISelectOption | null) => {
    setClient(option);
  };

  useEffect(() => {
    if (organizationArr) {
      setOrganization(organizationArr[0])
    }
  }, [organizationArr]);

  return (
    <form onSubmit={addUser}>
      <Select
        isClearable={true}
        value={organization}
        placeholder={"Selecione a organização..."}
        options={organizationArr ? organizationArr : []}
        onChange={selectOrganization}
        isLoading={loadingOrg}
        isDisabled={true}
      />
      <Select
        isClearable={true}
        value={network}
        placeholder={"Selecione a rede..."}
        options={networkArr ? networkArr : []}
        isLoading={loadingNet}
        onChange={selectNetwork}
        isDisabled={organization ? false : true}
      />
      <Select
        isClearable={true}
        value={client}
        placeholder={"Selecione o dispositivo..."}
        options={clientArr ? clientArr : []}
        onChange={selectClient}
        isLoading={loadingClient}
        isDisabled={network ? false : true}
      />
      <InputBox
        placeholder={"Horário de expiração do acesso"}
        value={dueDate}
        onChange={(event: any) => setDueDate(event.target.value)}
        type="datetime-local"
        min={new Date().toISOString().split("T")[0]}
      />
      <Button disabled={!organization || !network || !client || !dueDate || loadingRequest}>Agendar</Button>
      <ErrorMessage>{requestError}</ErrorMessage>
      <SuccessMessage>{requestSuccess}</SuccessMessage>
    </form>
  );
};
