import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: #0054AF;
  color: white;
  font-size: 20px;
  padding: 10px 30px;
  border: 1px solid #0054AF;
  border-radius: 5px;
  margin: 10px auto;
  cursor: pointer;
  box-shadow: 2px 2px #CCC;
  display: flex;
  &:hover:enabled {
    opacity: 0.8;
  }
  &:active:enabled {
    opacity: 0.4;
    box-shadow: -2px -2px #CCC;
  }
  &:disabled {
    background-color: #555;
    border: 1px solid #555;
    opacity: 0.5;
    cursor: default;
  }
`

