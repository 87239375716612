import axios from "axios";
import axiosRetry from "axios-retry";
import { IClient } from "types/client";
import { IConfigTemplate } from "types/configTemplate";
import { IDevice } from "types/device";
import { IFilterRequest } from "types/filterRequest";
import { IFilteringOptions } from "types/filteringOptions";
import { IPolicyStatus } from "types/initialPolicyStatus";
import { INetwork } from "types/network";
import { INetworkRequest } from "types/networkRequest";
import { IPolicyRequest } from "types/policyRequest";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
});

axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export const organizationDetails = async (accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get("/meraki", { headers: headers })
    .then((response) => {
      const options = response.data.map((data: INetwork) => {
        return { value: data, label: `${data.name}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const networkList = async (orgID: string, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/meraki/networks/${orgID}`, { headers: headers })
    .then((response) => {
      const options = response.data.map((data: INetwork) => {
        return { value: data, label: `${data.name}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const clientList = async (networkID: string, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/meraki/clients/${networkID}`, { headers: headers })
    .then((response) => {
      const options = response.data.map((data: IClient) => {
        return { value: data, label: `${data.ip}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options].filter((option) => option.label !== "null"),
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const configTemplatesList = async (orgID: string, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/meraki/configTemplates/${orgID}`, { headers: headers })
    .then((response) => {
      const options = response.data.map((data: IConfigTemplate) => {
        return { value: data, label: `${data.name}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const availableDevicesList = async (orgID: string, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/meraki/availableDevices/${orgID}`, { headers: headers })
    .then((response) => {
      const options = response.data.map((data: IDevice) => {
        return { value: data, label: `${data.serial}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const getPolicy = async (request: IPolicyStatus, accessToken: string | null) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/meraki/clients/${request.networkId}/policy/${request.macAddress}`, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });
};

export const saveSchedule = async (request: IPolicyRequest, accessToken: string | null) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .post(`/api/v1/schedule`, request, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAllSchedules = async (accessToken: string | null, itemsPerPage: number = 25, page: number = 1) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`api/v1/schedule?limit=${itemsPerPage}&page=${page}`, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createNetwork = async (request: INetworkRequest, accessToken: string | null) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .post(`/api/v1/network`, request, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const urlFilteringList = async (orgID: string, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/url-filtering/options/${orgID}`, { headers: headers })
    .then((response) => {
      const options = response.data.map((data: IFilteringOptions) => {
        return { value: data, label: `${data.name}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const updateFilters = async (request: IFilterRequest[], accessToken: string | null) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .put(`/url-filtering/allowList`, request, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getNetworkTrackingMethod = async (networkId: string, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/meraki/network/${networkId}/settings`, { headers: headers })
    .then((response) => {
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: response.data.clientTrackingMethod && response.data.clientTrackingMethod === "MAC address" ? "mac" : "ip",
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const getCresolUnits = async (accessToken: string | null) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`cresol-units`, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
