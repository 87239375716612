import { CenterDiv } from "components/CenterDiv";
import { RedText } from "./ErrorMessage.styles";

export const ErrorMessage = ({ ...props }) => {
  return (
    <CenterDiv>
      <RedText>{props.children}</RedText>
    </CenterDiv>
  );
};
