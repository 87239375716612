import { Button } from "components/Button";
import { CenterDiv } from "components/CenterDiv";
import { ErrorMessage } from "components/ErrorMessage";
import { Select } from "components/Select";
import { SuccessMessage } from "components/SuccessMessage";
import { useState, useEffect, KeyboardEventHandler } from "react";
import { organizationDetails, updateFilters, urlFilteringList } from "services/backend";
import { IFilterRequest } from "types/filterRequest";
import { ISelectOption } from "types/selectOption";

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replaceAll(' ', ''),
});

export const UrlForm = ({ accessToken }: any) => {
  const [cache, setCache] = useState<ISelectOption[] | null>(null);
  const [organizationArr, setOrganizationArr] = useState<ISelectOption[] | null>(null);
  const [networkArr, setNetworkArr] = useState<ISelectOption[] | null>(null);
  const [organization, setOrganization] = useState<ISelectOption | null>(null);
  const [network, setNetwork] = useState<ISelectOption[] | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [url, setUrl] = useState<readonly ISelectOption[]>([])
  const [loadingOrg, setLoadingOrg] = useState<boolean>(false);
  const [loadingNetwork, setLoadingNetwork] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<JSX.Element>(<></>)
  const [requestSuccess, setRequestSuccess] = useState<JSX.Element>(<></>)
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setUrl((prev) => {
          return prev.filter(option => option.label === inputValue).length === 0 ? [...prev, createOption(inputValue)] : [...prev]
        });
        setInputValue('');
        event.preventDefault();
    }
  };

  const addFilters = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRequestLoading(true);

    if (network === null) {
      setRequestError(<><strong>Erro:</strong> É necessário selecionar ao menos uma network/template</>)
      setRequestSuccess(<></>)
      setRequestLoading(false);
      return;
    }

    if (url.length === 0) {
      setRequestError(<><strong>Erro:</strong> É adicionar ao menos uma url</>)
      setRequestSuccess(<></>)
      setRequestLoading(false);
      return;
    }

    const request: IFilterRequest[] = network.map(networkData => {
      return {
        name: networkData.value.name,
        networkId: networkData.value.id,
        url: url.map(urlOption => urlOption.value)
      }
    })

    await updateFilters(request, accessToken)
      .then((res) => {
        if (res.status !== 200) {
          setRequestError(<><strong>Erro:</strong> {res.data.message}</>)
          setRequestSuccess(<></>)
          setRequestLoading(false);
        } else if (res.data.error) {
          setRequestError(<><strong>Erro:</strong> {res.data.message}</>)
          setRequestSuccess(<></>)
          setRequestLoading(false);
        } else {
          setRequestError(<></>)
          setRequestSuccess(
            <>
              <strong>{`URL(s) adicionada(s) com sucesso!`}</strong>
              <p>Templates/Networks atualizados: {request.map((network, i) => `${network.name}${i + 1 === request.length ? "." : "; "}`)}</p>
              <p>URL(s) liberada(s): {request[0].url.map((urls, i) => `${urls}${i + 1 === request[0].url.length ? "." : "; "}`)}</p>
            </>
          )
          clearForm();
          setRequestLoading(false)
        }
      })
    return
  };

  // Busca Lista de organizações (cache/request)
  useEffect(() => {
    if (!cache && accessToken) {
      setLoadingOrg(true);
      organizationDetails(accessToken).then((res) => {
        const getOrg = res.data.filter((item: any) => item.label === "Cresol Confederação");
        setCache(getOrg);
        setOrganizationArr(getOrg);
        setLoadingOrg(false);
      });
    } else {
      setOrganizationArr(cache);
    }
  }, [cache, accessToken]);

  // Busca Lista de networks/templates disponiveis para filtragem (cache/request)
  useEffect(() => {
    if (cache && organization && accessToken) {
      const orgIndex = cache.findIndex((obj: ISelectOption) => obj.value.name === organization.label);
      if (orgIndex !== -1 && !cache[orgIndex].value.networks) {
        setLoadingNetwork(true);
        urlFilteringList(cache[orgIndex].value.id, accessToken).then((res) => {
          const updateCache = cache;
          updateCache[orgIndex].value.networks = res.data;
          setCache(updateCache);
          setNetworkArr(cache[orgIndex].value.networks);
          setLoadingNetwork(false);
        });
      } else if (orgIndex !== -1) {
        setNetworkArr(cache[orgIndex].value.networks);
      }
    }
  }, [cache, organization, accessToken]);

  const selectOrganization = (option: ISelectOption | null) => {
    setOrganization(option);
  };

  const selectNetwork = (option: ISelectOption[] | null) => {
    setNetwork(option);
  };

  useEffect(() => {
    if (organizationArr) {
      setOrganization(organizationArr[0])
    }
  }, [organizationArr]);

  const clearForm = () => {
    selectNetwork(null);
    setUrl([]);
  }

  return (
    <form onSubmit={addFilters}>
      <Select
        isClearable={true}
        value={organization}
        placeholder={"Selecione a organização..."}
        options={organizationArr ? organizationArr : []}
        onChange={selectOrganization}
        isLoading={loadingOrg}
        isDisabled={true}
      />
      <Select
        isClearable={true}
        isMulti={true}
        value={network}
        placeholder={"Selecione o template..."}
        options={networkArr ? networkArr : []}
        isLoading={loadingNetwork}
        onChange={selectNetwork}
        isDisabled={!organization || loadingNetwork ? true : false}
      />
      <Select
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={(newValue: any) => setUrl(newValue)}
        onInputChange={(newValue: any) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Insira a(s) URL(s)..."
        value={url}
      />
      {
        !requestLoading ?
          <Button type="submit" disabled={!organization || !network || !url || requestLoading || inputValue !== ""}>Criar</Button>
          :
          <Button type="submit" disabled={true}>Criar</Button>}
      {
        requestLoading ?
          <CenterDiv>Carregando...</CenterDiv>
          :
          <>
            <ErrorMessage>{requestError}</ErrorMessage>
            <SuccessMessage>{requestSuccess}</SuccessMessage>
          </>
      }
    </form>
  );
};
