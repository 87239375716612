import { NavBarContainer } from "./NavBar.styles";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export const NavBar = () => {
  return (
    <NavBarContainer>
      <ul>
        <CustomLink to="/policy" className="Policy">Policy</CustomLink>
        <CustomLink to="/log" className="Log">Log</CustomLink>
        <CustomLink to="/network" className="Network">Network</CustomLink>
        <CustomLink to="/url" className="Url">URLs</CustomLink>
        {/* <CustomLink to="/cresol-units" className="CresolUnits">Unidades Cresol</CustomLink> */}
      </ul>
    </NavBarContainer>
  );
};

function CustomLink({ to, children, ...props }: any) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  const home = to === "/policy" ? true : false;
  const isHomeActive = useMatch({ path: home ? "/" : resolvedPath.pathname, end: true })
  return (
    <>
      <li className={isActive || isHomeActive ? "active" : ""}>
        <Link to={to} {...props}>{children}</Link>
      </li>
    </>
  )
}
