import { CenterDiv } from "components/CenterDiv";
import { GreenText } from "./SuccessMessage.styles";

export const SuccessMessage = ({ ...props }) => {
  return (
    <CenterDiv>
      <GreenText>{props.children}</GreenText>
    </CenterDiv>
  );
};
