import { CenterDiv } from "components/CenterDiv";
import { LogTable } from "components/LogTable"
import { useEffect, useState } from "react";
import { getAllSchedules } from "services/backend";
import { IColumns } from "types/Columns";
import { IRowData } from "types/rowData";

function createData(
  createdAt: string,
  adminName: string,
  adminMail: string,
  macAddress: string,
  organizationName: string,
  networkName: string,
  dueDate: string,
  status: string
): IRowData {
  return { createdAt, adminName, adminMail, macAddress, organizationName, networkName, dueDate, status };
}

const columns: readonly IColumns[] = [
  { id: 'createdAt', label: 'Access Release Time (GMT-3)', minWidth: 170, align: 'center', format: (value: string) => new Date(value).toLocaleString('pt-BR', {timeZone: "America/Sao_Paulo"}) },
  { id: 'adminName', label: 'Admin', minWidth: 100, align: 'center' },
  { id: 'adminMail', label: 'Email', minWidth: 170, align: 'center' },
  { id: 'macAddress', label: 'Mac Address', minWidth: 170, align: 'center' },
  { id: 'organizationName', label: 'Organization', minWidth: 170, align: 'center' },
  { id: 'networkName', label: 'Network', minWidth: 170, align: 'center' },
  { id: 'dueDate', label: 'Expiration Time (GMT-3)', minWidth: 170, align: 'center', format: (value: string) => new Date(value).toLocaleString('pt-BR', {timeZone: "America/Sao_Paulo"}) },
  { id: 'status', label: 'Status', minWidth: 100, align: 'center', format: (value: string) => value === "WAITING" ? "liberado" : "normal"}
];

export const Log = ({accessToken}: any) => {
 
  const [data, setData] = useState<[]>([]);
  const [rows, setRows] = useState<IRowData[] | []>([])

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [totalItems, setTotalItems] = useState<number>(0);


  useEffect(() => {
    if (accessToken) {
      setLoading(true);
      getAllSchedules(accessToken, rowsPerPage, currentPage)
      .then((res) => {
        setData(res.data.items);
        setCurrentPage(res.data.meta.currentPage);
        setRowsPerPage(res.data.meta.itemsPerPage);
        setTotalItems(res.data.meta.totalItems)
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
      });
    }
  }, [accessToken, rowsPerPage, currentPage]);

  useEffect(() => {
    if(data.length > 0) {
      const rows = data.map((row: IRowData) => {
        return createData(row.createdAt, row.adminName, row.adminMail, row.macAddress, row.organizationName, row.networkName, row?.dueDate, row.status)
      })
      setRows(rows);
    }
  }, [data])

  return (
    (loading ? (error ? <CenterDiv><p>Não foi possível efetuar a busca</p></CenterDiv> : <CenterDiv><p>Carregando...</p></CenterDiv>) : <LogTable rows={rows} columns={columns} currentPage={currentPage} setCurrentPage={setCurrentPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalItems={totalItems}/>)
  )
}
