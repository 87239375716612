import { useIsAuthenticated } from "@azure/msal-react";
import { Header } from "components/Header";
import { NavBar } from "components/NavBar";
import { AccessDenied } from "pages/AccessDenied";
import { CresolUnits } from "pages/CresolUnits";
import { Log } from "pages/Log";
import { Network } from "pages/Network";
import { NotFound } from "pages/NotFound";
import { Policy } from "pages/Policy";
import { Url } from "pages/Url";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export function MainRouter({ RequestAccessToken }: any) {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && accessToken === null) {
      RequestAccessToken(setAccessToken);
    }
  }, [accessToken, RequestAccessToken, isAuthenticated]);

  return (
    <BrowserRouter>
      <Header />
      <NavBar />
      <Routes>
        {['/policy', '/'].map((path, index) => <Route key={index} path={path} element={accessToken ? <Policy accessToken={accessToken} /> : <AccessDenied />} />)}
        <Route path="/log" element={accessToken ? <Log accessToken={accessToken} /> : <AccessDenied />} />
        <Route path="/network" element={accessToken ? <Network accessToken={accessToken} /> : <AccessDenied />} />
        <Route path="/url" element={accessToken ? <Url accessToken={accessToken} /> : <AccessDenied />} />
        {/* <Route path="/cresol-units" element={accessToken ? <CresolUnits accessToken={accessToken} /> : <AccessDenied />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
