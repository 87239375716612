import styled from "styled-components";

export const Button = styled.button`
  background-color: #0054AF;
  color: white;
  font-size: 12px;
  padding: 5px 5px;
  border: 1px solid #0054AF;
  border-radius: 2px;
  margin: auto;
  margin-right: 0px;
  cursor: pointer;
  /* box-shadow: 3px 3px #CCC; */
  &:hover:enabled {
    opacity: 0.8;
  }
`
