import styled from "styled-components";

export const StyledInputBox = styled.input`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  min-height: 38px;
  box-pack: justify;
  justify-content: space-between;
  padding: 2px 8px;
  &:focus {
    outline: none;
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
  &:input:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    
  }
`;

export const Label = styled.p`
  text-transform: lowercase;
  margin-bottom: 5px;
  &::first-letter {
    text-transform: uppercase;
  }
`;
