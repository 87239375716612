import styled from "styled-components";

export const Label = styled.p`
  text-transform: lowercase;
  margin-bottom: 5px;
  &::first-letter {
    text-transform: uppercase;
  }
`

export const Container = styled.div`
  text-align: left;
  max-width: 600px;
  margin: 30px auto;
`
