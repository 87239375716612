import { Container } from "components/Container";
import { StyledButton } from "./Button.styles";

export const Button = ({...props}) => {
  return (
    <Container>
      <StyledButton
        disabled={props.disabled}
        {...props}
      >{props.children}</StyledButton>
  </Container>
  );
};
