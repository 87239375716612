import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "config/authConfig";
import { Button } from "./AuthButton.styles";

function handleLogin(instance: any) {
  instance.loginRedirect(loginRequest)
    .catch((e: any) => { console.error(e) })
}

function handleLogout(instance: any) {
  instance.logoutRedirect(loginRequest)
    .catch((e: any) => { console.error(e) })
}

export const AuthButton = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    (isAuthenticated ? <Button onClick={() => handleLogout(instance)}>Logout</Button> : <Button onClick={() => handleLogin(instance)}>Login</Button>)
  );
};
