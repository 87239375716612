import { Container } from "components/Container";
import { StyledInputBox, Label } from "./InputBox.styles";

export const InputBox = ({...props}) => {
  return (
    <Container>
      <Label>{props.placeholder}</Label>
      <StyledInputBox
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.type === "text" ? `Digite ${props.article} ${props.placeholder.toLowerCase()}...` : undefined}
        min={props.min}
        maxLength={props.maxLength}
        disabled={props.isDisabled}
      />
  </Container>
  );
};
